/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import classNames from "classnames"
import React, { FunctionComponent, useEffect, useRef } from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import { Link, PageProps } from "gatsby"

import "../styles/layout.css"
import "../styles/layout.scss"
import { sections } from "../shared/sections"

type Props = {
  className?: string
  location: PageProps["location"]
  children?: React.ReactNode
}

const Layout: FunctionComponent<Props> = ({
  children,
  className,
  location,
}) => {
  const navBar = useRef<HTMLElement>(null)
  useEffect(() => {
    let previousScrollPosition = window.pageYOffset
    window.onscroll = function () {
      let currentScrollPosition = window.pageYOffset
      if (navBar.current) {
        navBar.current.style.top =
          previousScrollPosition > currentScrollPosition ? "0" : "-62.75px"
      }
      previousScrollPosition = currentScrollPosition
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  return (
    <div className="Layout">
      <Navbar bg="light" expand="lg" ref={navBar}>
        <Container>
          <Navbar.Brand>
            <Link to="/" className="no-style">
              Home
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-na" />
          <Navbar.Collapse id="responsive-navbar-na">
            <Nav className="mr-auto">
              {sections.map(({ title, pagePath }) => (
                <Link
                  className={classNames(
                    "link nav-link",
                    location.pathname === pagePath && "current"
                  )}
                  to={pagePath}
                  key={pagePath}
                >
                  {title}
                </Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <main className={className}>{children}</main>
    </div>
  )
}

export default Layout
