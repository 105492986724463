export const sections = [
  {
    title: "Music",
    pagePath: "/music",
    imagePath: "sections/lunar-ginger.jpg",
  },
  {
    title: "Resume",
    pagePath: "/resume",
    imagePath: "sections/business.jpg",
  },
  {
    title: "Anti-resume",
    pagePath: "/anti-resume",
    imagePath: "sections/ssenisub.jpg",
  },
  {
    title: "Contact",
    pagePath: "/contact",
    imagePath: "sections/contact.jpg",
  },
]
